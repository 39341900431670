import React from 'react';
import { Redirect } from 'react-router-dom';

const Fortress = window.cccisd.fortress;

const questTabPermissions = [
    'quest.admin',
    'quest.design',
    'quest.deploy',
    'quest.status',
    'quest.code',
    'quest.tables',
    'quest.permissions',
];

const QuestAdminHome = () => {
    if (!Fortress.auth()) {
        return <Redirect to="/" />;
    }
    if (Fortress.hasAnyAccess(questTabPermissions, 'anyTarget')) {
        return <Redirect to="/quest" />;
    }

    return (
        <div style={{ marginTop: '2em' }}>
            <h3>Welcome Project Admin</h3>
            <p>You do not currently have any Quest permissions.</p>
        </div>
    );
};

export default QuestAdminHome;
