import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
import { Link, Route } from 'react-router-dom';
import _find from 'lodash/find';
import Style from './style.css';

var Fortress = window.cccisd.fortress;
var Boilerplate = window.cccisd.boilerplate;
var AppDefs = window.cccisd.appDefs;

export default class Component extends React.Component {
    render() {
        let homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);
        return (
            <div className="landingPage" style={{ marginTop: '1.5em' }}>
                <div className="welcomeLogin">
                    <div className="row">
                        <div className="col-md-8">
                            <div>
                                <h1>
                                    <b>Welcome to Quest</b>
                                </h1>
                            </div>
                        </div>
                        <div className="col-md-4">
                            {!Fortress.auth() && (
                                <Route
                                    render={({ history }) => (
                                        <LoginForm
                                            onSuccess={() => {
                                                window.location = Boilerplate.url('/authRedirect');
                                            }}
                                        />
                                    )}
                                />
                            )}
                            {Fortress.auth() && (
                                <div className={Style.welcomeBox}>
                                    <div>Hello, {Fortress.user.username}</div>
                                    {homepageRoute && (
                                        <Link to={homepageRoute.url}>
                                            <button type="button" className="btn btn-primary">
                                                Go To Home
                                            </button>
                                        </Link>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
