import React from 'react';
import PropTypes from 'prop-types';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import NavBar from 'js/components/NavBar';
import Footer from 'cccisd-footer';
import style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

export default class FixedLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showPrimaryNav: PropTypes.bool,
        showSecondaryNav: PropTypes.bool,
        showFooter: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showPrimaryNav: true,
        showSecondaryNav: true,
        showFooter: true,
    };

    render() {
        return (
            <div className={style.wrapper}>
                {this.props.showPrimaryNav && (
                    <div className={style.header}>
                        <NavBar className={this.props.className} />
                    </div>
                )}

                {this.props.showSecondaryNav && Fortress.auth() && (
                    <div className={style.secondaryNav}>
                        <SecondaryNav
                            className={this.props.className}
                            navs={AppDefs.navs}
                            routes={AppDefs.routes}
                        />
                    </div>
                )}
                <div className={style.body}>
                    <div className={this.props.className}>{this.props.children}</div>
                </div>

                {this.props.showFooter && (
                    <div className={style.footer}>
                        <Footer className={this.props.className} />
                    </div>
                )}
            </div>
        );
    }
}
