import React, { useState, useEffect } from 'react';
import style from './style.css';
import robotboy1 from './robotboy_1.png';
import robotboy2 from './robotboy_2.png';
import robotboy3 from './robotboy_3.png';
import robotboy4 from './robotboy_4.png';

const RocketShipLayout = ({ children }) => {
    const [currRobotSrc, setCurrRobotSrc] = useState(robotboy1);
    const [isAnimationReverse, setIsAnimationReverse] = useState(false);
    const [intervalCount, setIntervalCount] = useState(0);

    useEffect(() => {
        const robotAnimationInterval = setInterval(() => {
            setIntervalCount(prev => prev + 1);
        }, 100);
        return () => clearInterval(robotAnimationInterval);
    }, []);

    useEffect(() => {
        // currently animating
        if (currRobotSrc !== robotboy1) {
            switch (currRobotSrc) {
                case robotboy2:
                    setCurrRobotSrc(isAnimationReverse ? robotboy1 : robotboy3);
                    if (isAnimationReverse) setIsAnimationReverse(false);
                    break;
                case robotboy3:
                    setCurrRobotSrc(isAnimationReverse ? robotboy2 : robotboy4);
                    break;
                case robotboy4:
                    setCurrRobotSrc(robotboy3);
                    setIsAnimationReverse(true);
                    break;
                default:
            }
            return;
        }

        // blink randomly about once every 6 seconds (1 / 60intervals)
        const isBlink = Math.random() < 0.017;
        if (isBlink) {
            setCurrRobotSrc(robotboy2);
        }
    }, [intervalCount]);

    return (
        <div className={style.page}>
            <div className={style.body}>{children}</div>
            <img src={currRobotSrc} alt="robot boy" className={style.robot} />

            {/* Preload images off-screen so there is no lag in the first animation */}
            <div className={style.preloadImages}>
                <img src={robotboy1} alt="robot boy - preload" className={style.robot} />
                <img src={robotboy2} alt="robot boy - preload" className={style.robot} />
                <img src={robotboy3} alt="robot boy - preload" className={style.robot} />
                <img src={robotboy4} alt="robot boy - preload" className={style.robot} />
            </div>
        </div>
    );
};

export default RocketShipLayout;
