import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Products = () => {
    const history = useHistory();

    useEffect(() => {
        history.push('/licensed-products');
    }, []);

    return <div>Products</div>;
};

export default Products;
